
.top-banner {
  height:200px;
  background:transparent;
  position: relative;
  margin-left:50px;
  margin-right:50px;
  margin-top:10px;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  z-index:10;
}
@media (max-width: 1199.98px) {
.top-banner {
    margin-left:15px;
    margin-right:15px;
}
}
@media (max-width: 575.98px) {
.top-banner {
    margin:0px;
}
}
.top-banner .Masthead {
  border-radius: 3px;
}


